<template>    
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">              
          <div class="d-flex justify-content-end">           
            <div>
              <router-link v-if="isUserLogin && !isMyAccountPage" class="nav-link" to="/staffMainPage">Staff Settings&nbsp;&nbsp;</router-link>                         
            </div>            
            <div>            
              <router-link v-if="isUserLogin" class="nav-link" to="/staff" @click.prevent="handleSignOut">Sign out</router-link>            
              <router-link class="nav-link" to="/staff" v-else>Home</router-link>              
            </div>
          </div>                                                                 
        </nav>        
      </div>                

    <main class="container-fluid mt-1">
      <router-view />
    </main>

    <footer>
      <div class="d-flex justify-content-center">
        <div style="margin-top: -2px; padding-right: 20px;">
          <a href="https://www.facebook.com/chenhometech"
          target="_blank"
          rel="noopener noreferrer"
          class="facebook-icon"
        ><i class="fab fa-facebook"></i></a>
        </div>
        <div><p>&copy; 2025 Chen Home Tech Inc. All rights reserved.</p></div>
      </div>
    </footer>

</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';
import "@fortawesome/fontawesome-free/css/all.min.css";

const store = useStore();    
const toast = useToast();
const route = useRoute();

const isUserLogin = computed(() => store.getters.isStaffLogin);
const isMyAccountPage = computed(() => route.path === '/staffMainPage');

function handleSignOut() {
  store.dispatch('logoutStaff');

  toast.info('Logout staff successful, redirecting to the home page... ');
}


</script>

<style scoped>
  
body, html {
  margin: 0;
  padding: 0;
  width: 100%;    
}

.facebook-icon {
  color: #1877f2;
  font-size: 20px;
  text-decoration: none;
}

.facebook-icon:hover {
  color: #145dbf;
}

</style>