<template>
    <div class="row">
   <div class="container">
    <div class="d-flex justify-content-between links align-items-center">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
        <a href="#" @click.prevent="backToAddress">{{ $t('addAddressLinkBack') }} ></a>
    </div>
      <hr class="divider-line0" />
    <h2>{{ $t('addAddressTitle') }}</h2>
    <form @submit.prevent="handleSubmit">
      <!-- Name Fields -->
      <div class="row w-100">
        <div class="d-flex justify-content-between data-row">
          <div class="col col-6" :class="{'has-error': errors.firstName}">         
            <input
              type="text"
              id="firstName"
              v-model="form.firstName"
              :placeholder="$t('createAccFirstName')"
              @focus="clearError('firstName')"
              required
            />
          </div>
          <div class="col col-6" :class="{'has-error': errors.lastName}">          
            <input
              type="text"
              id="lastName"
              v-model="form.lastName"
              :placeholder="$t('createAccLastName')"
              @focus="clearError('lastName')"
              required
            />
          </div>
        </div>     

        <p style="padding-top: 20px;" v-if="errors.firstName || errors.lastName" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.firstName || errors.lastName }} 
        </p>
      </div>      
      
      <hr class="divider-line" />

      <!-- Address info -->
      <div class="row w-100">
        <div class="d-flex justify-content-between data-row">        
          <div class="col col-6">
              <input
              type="text"
              id="unit"
              v-model="form.unit"
              :placeholder="$t('addAddressUnit')"                         
              />
          </div>
          <div class="col col-6">
              <input
              type="text"
              id="streetNo"
              v-model="form.streetNo"
              :placeholder="$t('addAddressStreetNo')"                             
              />
          </div>
        </div>
      </div>

      <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.streetName}">        
          <input
          type="text"
          id="streetName"
          v-model="form.streetName"
          :placeholder="$t('addAddressStreetName')"
          @focus="clearError('streetName')"         
          required                                  
          />        
        </div>

        <p v-if="errors.streetName" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.streetName }} 
        </p>
      </div>

      <div class="row w-100">
        <div class="d-flex justify-content-between data-row">
          <div class="col col-6" :class="{'has-error': errors.city}">
              <input
              type="text"
              id="city"
              v-model="form.city"
              :placeholder="$t('addAddressCity')"
              @focus="clearError('city')"          
              required           
              />
          </div>
          <div class="col col-6" :class="{'has-error': errors.postal}">
              <input
              type="text"
              id="postal"
              v-model="form.postal"
              :placeholder="$t('addAddressPostal')"
              @focus="clearError('postal')"                           
              required       
              />
          </div>
        </div>

        <p style="padding-top: 20px;" v-if="errors.city || errors.postal" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.city || errors.postal }} 
        </p>
      </div>

      <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.province}">        
          <select id="province" v-model="form.province"  @focus="clearError('province')" required>
              <option disabled value="0">{{ $t('addAddressProvince') }}</option>
              <option v-for="province in provinces" :key="province.Id" :value="province.Id">
                  {{ province.name }}
              </option>
          </select>       
        </div>

        <p v-if="errors.province" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.province }} 
        </p>
      </div>
      
      <div class="row w-100">
        <!-- Is default -->
        <div class="row checkbox-row">            
          <label for="isDefault" style="width: auto;">
              <input
              type="checkbox"
              id="isDefault"
              v-model="form.isDefault"              
              />
              <span>{{ $t('addAddressMarkDefault') }}</span>
            </label>                  
        </div>      
      </div>

      <hr class="divider-line1" />

      <div class="row w-100">
        <div class="d-flex justify-content-center" style="padding-bottom: 30px;">        
          <a class="toggle-link" :class="{'d-flex align-items-center': !isShowBuildingDetais }" href="#" @click.prevent="switchShowBuildingDetails"><span class="arrow" :class="{ 'rotate': isShowBuildingDetais }"></span><span>{{ $t('addAddressLinkBuildingDetail') }}</span></a>
        </div>
      </div>

      <div id="buildingDetails" v-if="isShowBuildingDetais">
        <div class="row w-100">
          <div class="d-flex justify-content-between data-row">        
              <div class="col col-6" :class="{'has-error': errors.buildingType}">
                  <select id="buildingType" v-model="form.buildingType"  @focus="clearError('buildingType')">
                      <option disabled value="0" >{{ $t('addAddressBuildingType') }}</option>      
                      <option v-for="buildingType in buildingTypes" :key="buildingType.Id" :value="buildingType.Id">
                          {{ $t(buildingType.key) }}
                      </option>          
                  </select>
              </div>
              <div class="col col-6" :class="{'has-error': errors.kitchenType}">
                  <select id="kitchenType" v-model="form.kitchenType"  @focus="clearError('kitchenType')">
                      <option disabled value="0" >{{ $t('addAddressKitchenDetail') }}</option>      
                      <option v-for="kitchenType in kitchenTypes" :key="kitchenType.Id" :value="kitchenType.Id">
                          {{ $t(kitchenType.key) }}
                      </option>          
                  </select>
              </div>
          </div>        

          <p v-if="errors.buildingType || errors.kitchenType" class="error-message">
            <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.buildingType || errors.kitchenType }} 
          </p>
        </div>

        <div class="row w-100">
          <div class="row data-row">
              <select id="areaType" v-model="form.areaType"  @focus="clearError('areaType')" >
                  <option disabled value="0">{{ $t('addAddressAreaDetail') }}</option>
                  <option v-for="areaType in areaTypes" :key="areaType.Id" :value="areaType.Id">
                      {{ $t(areaType.key) }}
                  </option>
              </select>
          </div>          
       
          <div class="row data-row">
              <select id="bathType" v-model="form.bathType"  @focus="clearError('bathType')" >
                  <option disabled value="0">{{ $t('addAddressBathDetail') }}</option>
                  <option v-for="bathType in bathTypes" :key="bathType.Id" :value="bathType.Id">
                      {{ $t(bathType.key) }}
                  </option>
              </select>
          </div>

          <p v-if="errors.areaType || errors.bathType" class="error-message">
            <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.areaType || errors.bathType }} 
          </p>
        </div>

        <div class="row w-100">
          <div class="row checkbox-row">                    
            <label for="hasHalfbath" style="width: auto;">
              <input
              type="checkbox"
              id="hasHalfbath"
              v-model="form.hasHalfbath"
              />
              <span>{{ $t('addAddressBathhashalf') }}</span>
            </label>                      
          </div>     
        </div> 

        <hr class="divider-line1" />

        <div class="row w-100">
          <div class="row data-row">
              <select id="driveWayType" v-model="form.driveWayType"  @focus="clearError('driveWayType')">
                  <option disabled value="0">{{ $t('addAddressDrivewayDetail') }}</option>
                  <option v-for="driveWayType in driveWayTypes" :key="driveWayType.Id" :value="driveWayType.Id">
                      {{ $t(driveWayType.key) }}
                  </option>
              </select>
          </div>

          <p v-if="errors.driveWayType" class="error-message">
            <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.driveWayType }} 
          </p>
        </div>

        <div class="row w-100">
          <div class="row checkbox-row">         
            <div class="d-flex justify-content-center">
              <div>
                <label for="isCornerLot" style="width: auto;">
                  <input
                  type="checkbox"
                  id="isCornerLot"
                  v-model="form.isCornerLot"
                  />
                  <span>{{ $t('addAddressIsCornerLot') }}</span>
                </label>    
              </div>     
              <div style="padding-left: 30px;">
                <label for="hasNoWalkway" style="width: auto;">
                  <input
                  type="checkbox"
                  id="hasNoWalkway"
                  v-model="form.hasNoWalkway"
                  />
                  <span>{{ $t('selSvrDetail.propertyNoWalkway') }}</span>
                </label>
              </div>             
            </div>       
          </div>               
        </div>        
      </div>            

      <hr class="divider-line1" />

      <!-- Submit Button -->
      <div class="row w-100">
        <div class="row">
          <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ $t('addAddressBtnAdd') }}</span>
              <span v-else class="spinner"></span></button>
        </div>
      </div>
    </form>      
  </div>
</div>
</template>
  
  <script>
  import { useStore } from 'vuex';
  import { ref } from 'vue';
  import apiClient from '@/apiClient.js';
  import { useRouter } from 'vue-router';  
  import { useToast } from 'vue-toastification';
  import { getCurrentInstance } from 'vue';
  import { AREATYPES, BATHTYPES, BUILDINGTYPES, DRIVEWAYTYPES, KITCHENTYPES, PROVINCES } from '@/constants';

  
  export default {
    name: "AddUserAddress",
    setup() {      
      const store = useStore();
      const router = useRouter();      
      const curUser = store.getters.user;
    
      const form = ref({
        firstName: '',
        lastName: '',                
        unit: '',
        streetNo: '',
        streetName: '',        
        city: '',
        postal: '',
        province: 0,
        isDefault: false,        
        buildingType: 0,
        bathType: 0,
        kitchenType: 0,
        areaType: 0,
        hasHalfbath: true,
        isCornerLot: false,
        driveWayType: 0,
        hasNoWalkway: false,
        });      
      const errors = ref({});
      const loading = ref(false);               
      const toast = useToast();
      const { proxy } = getCurrentInstance();
      const isShowBuildingDetais = ref(false);


      if(!store.getters.islogin) {
            router.push('/');
        }

        const backToAddress = () => {
            router.push('/userAddressDetail');
        };
        
        const switchShowBuildingDetails = () => {
            isShowBuildingDetais.value = ! isShowBuildingDetais.value;
        };        

        const handleSubmit = async () => {
        
            loading.value = true;

            console.log('Form Submitted', form.value);            

            // Check province
            if(form.value.province == 0) {
              errors.value['province'] = 'A province must be selected.';
              loading.value = false;
              return;
            }            

            try {

                const response = await apiClient.post('/Account/addAccountAddress',{
                    userId: curUser.userId,
                    firstName: form.value.firstName,
                    lastName: form.value.lastName,            
                    unit: form.value.unit,
                    streetNo: form.value.streetNo,
                    streetName: form.value.streetName,
                    city: form.value.city,
                    postal: form.value.postal,
                    provinceId: form.value.province,
                    isDefault: form.value.isDefault,
                    buildingType: form.value.buildingType,
                    kitchenType: form.value.kitchenType,
                    areaType: form.value.areaType,
                    bathType: form.value.bathType,
                    hasHalfbath: form.value.hasHalfbath,
                    driveWayType: form.value.driveWayType,
                    isCornerLot: form.value.isCornerLot,
                    hasNoWalkway: form.value.hasNoWalkway,
                });
             
                errors.value = {};

                if(response && response.data) {
                  toast.success(response.data.msg);
                }

                setTimeout(() => {
                  router.push('/userAddressDetail');
                }, 2000);

            } catch(error) {
                console.error('Add account address failed:', error);

                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                }
                else {                    
                  if(error.response && error.response.status == 401) {
                    toast.error('Your session has expired, please login again.');           
                    store.dispatch('logout');
                    router.push('/');
                  } else {
                    toast.error(error.code);                    
                  }                            
                }

            } finally {
                loading.value = false;
            }
        };

        const clearError = (field) => {
            if(errors.value[field]) {               
              delete errors.value[field];                           
            }            
        };

      return {
        form,
        handleSubmit,                     
        errors,
        clearError,
        loading,               
        curUser,
        backToAddress,
        provinces: PROVINCES,
        isShowBuildingDetais,
        switchShowBuildingDetails,
        buildingTypes: BUILDINGTYPES,        
        kitchenTypes: KITCHENTYPES,
        areaTypes: AREATYPES,
        bathTypes: BATHTYPES,
        driveWayTypes: DRIVEWAYTYPES,
      };
    }
  };
  </script>
  
  <style scoped>

.toggle-link {
  display: inline-block;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;  
  border-radius: 5px;
}

.arrow {
  display: inline-block;
  margin-right: 3px;
  border: solid #007bff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg); 
  transition: transform 0.3s;
}

.arrow.rotate {
  transform: rotate(-135deg); 
}

  .container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;      
  justify-content: center;
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 20px;
  padding-bottom: 20px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;  
  margin-top: 10px;
  margin-bottom: 30px;
}

.row {
  margin-bottom: 15px;
  display: flex;    
  justify-content: center;
}


.data-row {        
  max-width: 560px;
}

.col {
  display: flex;
  flex-direction: column;      
}

.col-6 {
  width: calc(50% - 5px);
}

.col-6:first-child {
  margin-right: 10px;
}

.col-7 {
  width: 100%;  
  justify-content: center;
  display: flex;
}

.col-validation {
  width: 170px;     
}

.col-validation-text {
  width: calc(50% - 5px);
  max-width: 240px;
}

.col-validation-label {  
  width:100%;
  display: flex;
  justify-content: center;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;  
  box-sizing: border-box;
}


button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.checkbox-row {
  max-width: 560px;  
  padding: 0px;     
}

.checkbox-row input[type="checkbox"] {
  margin-right: 6px;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.col.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.error-box {
  margin-bottom: 20px; 
  padding: 30px;
  background-color: #fae9a3;
  border: 1px solid #dbc156;
  border-radius: 5px;
  text-align: center;
  color: #333;
  position: relative;
  font-size: 14px;  
  width: auto;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.error-box::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffe9c8 transparent;
}

.datepicker-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vue3-datepicker__calendar {
  position: absolute;
  top: 50px; 
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.vue3-datepicker__calendar-header {
  background-color: #f0f0f0;
}

.vue3-datepicker__calendar-day:hover {
  background-color: #007bff;
  color: #fff;
}

.links {
  text-align: left;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}


</style>
  