<template>
    <div class="container">

        <div class="d-flex justify-content-between links" style="align-items: center;">
            <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
            <a href="#" @click.prevent="backToStep1">{{ $t('selSvrDetail.linkPreStep') }} ></a>
        </div>
        <hr class="divider-line0"/>        

        <div class="row">
          <h2>{{ $t('selSvrDetail.mainTitle') }}
            <p style="font-size: 16px; font-weight: 600; color: chocolate;"><span style="font-size: 15px; font-weight: 600; color:#333;">{{ $t('selSvrAdr.curSvrTitle') }}:</span> {{ $t(txtCatalog) }}</p>
          </h2>
          <el-descriptions :title="$t('selSvrDetail.addressTitle')">
            <el-descriptions-item label=""><span style="color:chocolate; font-weight: 600; margin: 0px; padding-top: 0px;"> {{ resultAddress.unit ? resultAddress.unit:'' }} {{ resultAddress.streetNo ? resultAddress.streetNo:'' }} 
              {{ resultAddress.streetName }}<br>&nbsp;&nbsp;&nbsp;&nbsp;{{ resultAddress.city }}, {{ provinceName }} {{ resultAddress.postal }}</span>
            </el-descriptions-item>                                        
          </el-descriptions>          
        </div>  

        <div class="row">          
        <el-collapse class="transparent-collapse">
          <el-collapse-item name="1">
            <template #title>
                <span class="custom-title">{{ $t('selSvrDetail.propertyTitle') }}</span>
              </template>
              <template #icon="{ isActive }">
                <span class="icon-ele">
                  {{ isActive ? 'Expanded' : 'Collapsed' }}
                </span>
              </template>
            <div>
            <el-collapse style="border: none;">                  
              <el-collapse-item name="1">
                <template #title>
                <span class="custom-subtitle">{{ $t('selSvrDetail.propertyBdType') }}</span>
                </template>
                <div>
                 <el-tag size="small" v-if="resultAddress.buildingType == 0" type="danger">{{ $t('selSvrDetail.propertyNotSet') }}</el-tag>            
                 {{ $t(transBuildingType) }}    
                </div>                
              </el-collapse-item>
              <el-collapse-item name="2">
                <template #title>
                <span class="custom-subtitle">{{ $t('selSvrDetail.propertyArea') }}</span>
                </template>
                <div class="d-flex justify-content-start" style="align-items: center;">
                  {{ $t(transAreaDetail) }}
                  <el-tag size="small" v-if="resultAddress.areaType == 0" type="danger">{{ $t('selSvrDetail.propertyNotSet') }}</el-tag>
                </div>
                <div class="d-flex justify-content-start" style="align-items: center; padding-top: 6px;">
                  <div v-if="resultAddress.hasNoWalkway && resultAddress.areaType != 0"><el-tag size="small">{{ $t('selSvrDetail.propertyNoWalkway') }}</el-tag></div>
                  <div v-if="resultAddress.hasNoWalkway && resultAddress.areaType != 0" style="padding-right: 10px;">&nbsp;</div>                  
                  <div><el-tag size="small" v-if="resultAddress.isCornerLot && resultAddress.areaType != 0" type="warning">{{ $t('selSvrDetail.propertyCornerLot') }}&nbsp;</el-tag></div>                                       
                </div>                              
              </el-collapse-item>
              <el-collapse-item name="3">
                <template #title>
                <span class="custom-subtitle">{{ $t('selSvrDetail.propertyKitchen') }}</span>
                </template>
                <div>
                  <el-tag size="small" v-if="resultAddress.kitchenType == 0" type="danger">{{ $t('selSvrDetail.propertyNotSet') }}</el-tag>
                  {{ $t(transKitchenDetail) }}
                </div>                
              </el-collapse-item>
              <el-collapse-item name="4">
                <template #title>
                <span class="custom-subtitle">{{ $t('selSvrDetail.propertyBath') }}</span>
                </template>
                <div>
                  <el-tag size="small" v-if="resultAddress.hasHalfbath === false && resultAddress.bathType != 0">{{ $t('selSvrDetail.propertyNoHalfBath') }}</el-tag>  
                  <el-tag size="small" v-if="resultAddress.bathType == 0" type="danger">{{ $t('selSvrDetail.propertyNotSet') }}</el-tag> 
                  {{ $t(transBathDetail) }}
                </div>        
              </el-collapse-item>
              <el-collapse-item name="5">
                <template #title>
                <span class="custom-subtitle">{{ $t('selSvrDetail.propertyDriveway') }}</span>
                </template>
                <div>
                  <el-tag size="small" v-if="resultAddress.driveWayType == 0" type="danger">{{ $t('selSvrDetail.propertyNotSet') }}</el-tag>
                  {{ $t(transDrivewayDetail) }}
                </div>        
              </el-collapse-item>
            </el-collapse>
          </div>
    </el-collapse-item>
  </el-collapse>
        </div>
                        
        <div class="row">
          <el-collapse class="transparent-collapse" v-model="activeService">
            <el-collapse-item name="1" >
              <template #title>
                <span class="custom-title">{{ $t('selSvrDetail.typeTitle') }}</span>
              </template>
              <template #icon="{ isActive }">
                <span class="icon-ele">
                  {{ isActive ? 'Expanded' : 'Collapsed' }}
                </span>
              </template>

              <el-radio-group v-model="selectedServiceItem">
              <div class="row">
                <div 
                  v-for="service in resultServices"
                  :key="service.serviceId"
                  style="margin-right: 0px; margin-left: 00px;"   
                  class="col-md-6 col-sm-6  mb-4 elRadioWidth"     
                >
                  <el-radio                                         
                    :value="service.serviceId"
                    size="large" border                       
                    style="height: auto; width: 100%; padding: 16px;"         
                    class="radio-text"
                    >
                    <strong>{{ service.name }}</strong><br>
                    {{ service.description }}  
                  </el-radio>

                  <div v-if="selectedServiceItem === service.serviceId" style="padding-top: 10px; padding-bottom: 10px;" class="summary-container">
                    <div class="summary">
                      <div style="text-align: center; margin: 6px;">
                        <h4>{{ $t('selSvrDetail.summaryTitle') }}</h4>
                       <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
                          <div>{{ $t('selSvrDetail.summaryBasePoints') }}: </div>
                          <div>
                            <a href="#" @click.prevent="dialogCheckPointsVisible = true" style="color:firebrick;">{{ service.basePoints }}</a>         
                            <el-dialog v-model="dialogCheckPointsVisible" :title="$t('selSvrDetail.summaryCheckPointDlgTitle')" width="400" destroy-on-close>
                            <el-table :data="service.checkPointItems">                                
                              <el-table-column property="itemName" :label="$t('selSvrDetail.summaryCheckPointDlgColName')" width="200" />
                              <el-table-column property="timeConsuming" :label="$t('selSvrDetail.summaryCheckPointDlgColTime')" />
                            </el-table>
                          </el-dialog>                               
                          </div>
                       </div>
                       <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
                          <div>{{ $t('selSvrDetail.summaryAddPoints') }}: </div>
                          <div>
                            <a href="#" @click.prevent="dialogAdditionalItemsVisible = true" style="color:firebrick;">{{ service.additionalPoints }}</a>
                            <el-dialog v-model="dialogAdditionalItemsVisible" :title="$t('selSvrDetail.summaryAddPointDlgTitle')" width="400" destroy-on-close>
                            <el-table :data="service.additionalItems">                                
                              <el-table-column :label="$t('selSvrDetail.summaryAddPointDlgColName')" width="400">                              
                                <template #default="scope">
                                  <span :class="getItemColor(scope.row.countingType)">{{ $t(getAdditionalItemName(scope.row.condType, scope.row.propertyAttr)) }}</span>
                                </template>
                              </el-table-column>
                            </el-table>
                          </el-dialog>         
                          </div>
                       </div>
                       <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
                          <div style="color:green;">{{ $t('selSvrDetail.summaryRankDiscount') }}: </div>
                          <div style="color:green;">{{ (rankDiscount/10).toFixed(1) }}%</div>
                       </div>
                       <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>
                       <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
                          <div style="color: firebrick; font-weight: 600;">{{ $t('selSvrDetail.summaryTotalPoints') }}:</div>
                          <div style="color:firebrick; font-weight: 600">{{ service.totalPoints }}</div>
                       </div>
                       <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 10px;">
                          <div></div>
                          <div style="color:#333;">{{ $t('selSvrDetail.summaryEstimated') }}: ${{(service.totalPoints/100).toFixed(1)}}</div>
                       </div>
                       <div v-show="service.specialNotice" style="font-size:14px; text-align: left; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 0px;">
                          <div style="color:#007185;"><img src="/misc/notice.png" style="width: 18px"/>&nbsp;{{ $t('selSvrDetail.summaryNotice') }}: {{ service.specialNotice }}</div>                          
                       </div>
                      </div>
                    </div>
                    <form @submit.prevent="handleSubmit(service)">
                    <div class="button-container">
                      <button type="submit" :disabled="loading" class="submit-button" style="width: 150px;">
                        <span v-show="!loading" style="font-size: 13px;">{{ $t('selSvrDetail.btnSubmit') }}</span>
                        <span v-show="loading" class="spinner"></span>
                      </button>
                    </div>     
                    </form>                                                
                  </div>

                </div>                
              </div>
            </el-radio-group>
            </el-collapse-item>
          </el-collapse>
        </div>      
      
    </div>
</template>
  

<script setup>

import { ref,onMounted,computed } from 'vue';
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';
import apiClient from '@/apiClient.js';
import { AREATYPES, BATHTYPES, BUILDINGTYPES, DRIVEWAYTYPES, KITCHENTYPES, PROVINCES, SERVICECATALOG } from '@/constants';


  const router = useRouter();
  const store = useStore();
  const { proxy } = getCurrentInstance();
  const toast = useToast();
  const errors = ref({});
  const resultAddress = ref({});    
  const activeService = ref('1');
  const selectedServiceItem = ref('');
  const resultServices = ref([]);
  const loading = ref(false);   
  const rankDiscount = ref('');
  const dialogCheckPointsVisible = ref(false);
  const dialogAdditionalItemsVisible = ref(false);  


  const curUser = store.getters.user;
    if(!store.getters.islogin) {
        router.push('/');
    }


  const addressId = router.currentRoute.value.params.addressId;
  const catalog = Number(router.currentRoute.value.params.catalog);

  const catalogItem = SERVICECATALOG.find(item => item.Id === catalog);        
  const txtCatalog = catalogItem ? catalogItem.key : 'emptyKey';

  function backToStep1() {
    router.push({ name: 'selectServiceAddress', params: { catalog: catalog }});
  }

  const transBuildingType = computed(() => {
        const building = BUILDINGTYPES.find(bd => bd.Id === resultAddress.value.buildingType);                
        return building ? building.key : 'emptyKey';              
      });      

  
  const transAreaDetail = computed(() => {
    const item = AREATYPES.find(item => item.Id === resultAddress.value.areaType);
    return item ? item.key : 'emptyKey';
  });

  const transKitchenDetail = computed(() => {
    const item = KITCHENTYPES.find(item => item.Id === resultAddress.value.kitchenType);
    return item ? item.key : 'emptyKey';
  });

  const transBathDetail = computed(() => {
    const item = BATHTYPES.find(item => item.Id === resultAddress.value.bathType);
    return item ? item.key : 'emptyKey';
  });

  const transDrivewayDetail = computed(() => {
    const item = DRIVEWAYTYPES.find(item => item.Id === resultAddress.value.driveWayType);
    return item ? item.key : 'emptyKey';
  });

  const provinceName = computed(() => {
    const item = PROVINCES.find(item => item.Id === resultAddress.value.provinceId);
    return item ? item.name : '';
  });


  function getItemColor(countingType) {
    if(countingType == 3) {
      return 'span-blue';
    }
    else {
      return 'span-red';
    }
  }


  function getAdditionalItemName (condType, attr) {
    
    let item;
    
    switch(Number(condType))
    {
      case 1:
        item = BUILDINGTYPES.find(item => item.Id === Number(attr));        
        break;

      case 2:        
        if(Number(attr) === 5) {
          return 'On corner lot';
        } else if(Number(attr) === 6) {
          return 'Has walkway';
        } else {
          item = AREATYPES.find(item => item.Id === Number(attr));
        }        
        break;

      case 4:
        item = KITCHENTYPES.find(item => item.Id === Number(attr));
        break;

      case 5:
        if(Number(attr) === 4) {
          return 'All bathrooms are full baths';
        }
        item = BATHTYPES.find(item => item.Id === Number(attr));
        break;

      case 7:
        item = DRIVEWAYTYPES.find(item => item.Id === Number(attr));
        break;
    }

    return item ? item.key : 'emptyKey';
  }

  
  async function loadServiceDetail() {

    try {
      const response = await apiClient.post('/Service/getUserServiceDetail', {
        userId: curUser.userId,
        serviceCatalog: catalog,
        addressId: addressId
      });

      resultAddress.value = response.data.contactInfo;
      resultServices.value = response.data.services;   
      rankDiscount.value = response.data.rankDiscount;

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
              if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logout');
                router.push('/');
              } else {
                toast.error(error.code);                    
              }                                                            
        }
    }
  }

  function handleSubmit(item) {

    if(!selectedServiceItem.value) {
      toast.error('A detail service type must be selected.');      
      return;
    }

      loading.value = true;

      setTimeout(() => {
        loading.value = false;
        
        if(item?.periodType === 0) {
          router.push({name:'selectServiceTime', params: { catalog: catalog, addressId: addressId, serviceId: selectedServiceItem.value }});
        } else {      
          router.push({name:'SelectServicePeriod', params: { catalog: catalog, addressId: addressId, serviceId: selectedServiceItem.value, period: item?.periodType }});
        } 

      }, 200);
  }


  onMounted(() =>{
    loadServiceDetail();
  });

</script>


<style scoped>

.span-red {
  color:brown;
}
.span-blue {
  color:dodgerblue;
}

.summary-container {
  display: flex;
  flex-direction: column; 
  align-items: center;    
  justify-content: center; 
  width: 100%;            
  padding-top: 20px;
  padding-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center; 
  width: 100%;
  margin-bottom: 10px; 
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;  
  max-width: 600px;  
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 00px;
  width: 94%;
}

.elRadioWidth {
    width: 600px;
}  

@media (max-width: 530px) {
    .elRadioWidth {
        width:360px;
    }   
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 94%;  
}

button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

h4 {
  margin-bottom: 0px;  
  padding-top: 10px;
  font-weight: 700;
  font-size: 15px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 4px;
}

.custom-title {
  font-weight: bold; /* Makes the title bold */
  font-size: 16px;
}

.custom-subtitle {
  font-weight: bold; /* Makes the title bold */  
}

.transparent-collapse {
  background-color: transparent !important; /* Set the background to transparent */
  width: 100%;
  padding-top: 0px; 
  padding-bottom: 30px;
  border: none;
}

.icon-ele {
  margin: 0 8px 0 auto;
  color: #409eff;
}

.radio-group {
  max-width: 560px; 
}

.radio-text {
  white-space: normal; 
  word-wrap: break-word;
  display: inline-block; 
  align-items: flex-start;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

</style>
  