<template>    
  <div class="container">
    <div class="row" style="padding-bottom: 40px; padding-top: 10px;">
      <div class="d-flex justify-content-center">
        <div>
            <img src="/misc/logo.jpg" width="200px">
        </div>        
      </div>
      <div class="d-flex justify-content-center" style="padding-top: 6px;">
          <div style="font-size: 24px; font-weight: 600;">{{ $t('about.mainTitle') }}</div>                
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 26px;">
        <ul>
          <li>{{ $t('about.p1') }}</li>
          <li style="padding-top: 10px;">{{ $t('about.p2') }}</li>
          <li style="padding-top: 10px;">{{ $t('about.p3') }}</li>
          <li style="padding-top: 10px;">{{ $t('about.p4') }}</li>
        </ul>        
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 26px;">
        <div>{{ $t('about.introTitle') }}</div>  
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 10px;">
        <div style="font-weight: 600;">{{ $t('about.teamTitle') }}</div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><LocationInformation /></el-icon></div>
        <div style="font-weight: 600;">18 Tuscany Meadows Common NW<br>Calgary, AB, T3L2L2</div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><Phone /></el-icon></div>
        <div style="font-weight:600; font-size: 16px;">1-825-288-9678 </div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><MessageBox /></el-icon></div>
        <div style="font-weight:600;color:blue; font-size: 16px;">chenhometechcanada@gmail.com</div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 10px; ">
        <div style="font-weight: 600;">{{ $t('about.facebookTitle') }}</div>        
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px; font-size: 14px;">
        <div style="padding-right: 6px;">{{ $t('about.cleaning') }}</div>
        <div><a href="https://www.facebook.com/chenhometech" target="_blank">www.facebook.com/chenhometech</a></div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px; font-size: 14px;">
        <div style="padding-right: 6px;">{{ $t('about.infoTech') }}</div>
        <div><a href="https://www.facebook.com/chenhometechit"  target="_blank">www.facebook.com/chenhometechit</a></div>
      </div>
    </div>        
  </div>
</template>
  
  <script setup>
  


  </script>
  
  <style scoped>

  </style>
  