<template>    
    <div class="container">
        <div class="d-flex justify-content-between" style="align-items: center;">
            <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>            
            <el-select
              v-model="filterItemVal"
              placeholder="Filter"              
              style="width: 140px"
              @change="filterItemChange"
            >
              <el-option
                v-for="item in filterItems"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select>            
        </div>

        <hr class="divider-line0"/>

        <div> <h2>{{ $t('userPointHistory.mainTitle') }}<br><span style="font-size: 14px;">{{ $t('userPointHistory.pointBalance') }} <span style="font-weight: 600; color: firebrick; font-size: 15px;">{{ curPoints }}</span></span></h2> </div>

        <div class="row" style="padding-bottom: 30px; padding-top: 0px;">
          <ul v-infinite-scroll="load" :infinite-scroll-disabled="disabled" class="infinite-list" style="overflow: auto; padding-top: 0px; padding-bottom: 0px;"  >
            <li v-for="i in count" :key="i" class="infinite-list-item" >
              <div class="summary" v-if="historyData[i-1]">
                <div style="background-color: #F0F2F2; padding-top: 6px; padding-bottom: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px; color:#333;" class="d-flex justify-content-between">                  
                  <div class="d-flex justify-content-start">
                    <div style="margin-top: 2px; padding-right: 4px;"><el-icon><Timer /></el-icon></div>
                    <div>{{ new Date(historyData[i-1]?.strOccurredTime).getMonth()+1 }}.{{ new Date(historyData[i-1]?.strOccurredTime).getDate()}}, {{ new Date(historyData[i-1]?.strOccurredTime).getFullYear() }} {{ new Date(historyData[i-1]?.strOccurredTime).getHours() }}:{{ new Date(historyData[i-1]?.strOccurredTime).getMinutes() }}</div>
                    <div style="font-weight: 600; padding-left: 4px;">{{ proxy.$getTimeZone(new Date(historyData[i-1]?.strOccurredTime)) }}</div>
                  </div>
                  <div v-if="historyData[i-1]?.isReward" style="margin-top: -3px; margin-right: 60px;"><el-tag type="primary">Reward</el-tag></div>
                  <div class="d-flex justify-content-start">
                    <div style="margin-top: 2px; padding-right: 4px;"><el-icon><UserFilled /></el-icon></div>
                    <div style="padding-right: 6px;">{{ getOpt(historyData[i-1]?.optRole) }}</div>
                  </div>
                </div>
                <div><hr class="divider-line1"/></div>
                <div style="padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 16px;" class="d-flex justify-content-center">
                  <div style="font-weight: 600; padding-right: 2px;" :class="getAmountFontColor(historyData[i-1]?.paymentId===0, historyData[i-1]?.isReward)">{{ getAmountSymbol(historyData[i-1]?.paymentId===0, historyData[i-1]?.isReward)}}</div>
                  <div style="font-weight: 600; padding-right: 4px;" :class="getAmountFontColor(historyData[i-1]?.paymentId===0, historyData[i-1]?.isReward)">{{ historyData[i-1]?.amount }}</div>                  
                </div>
              </div>
            </li>
          </ul>
          <p v-if="noMore" style="text-align: center; color: #333; font-size: 12px; padding-top: 8px;">{{ $t('userPayment.noMoreText') }}</p>
          <p v-if="historyData.length === 0" style="text-align: center; color: #333; font-size: 12px; padding-top: 8px;">{{ $t('userPayment.noDataText') }}</p>
        </div>        
    </div>
</template>


<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { onMounted,ref,getCurrentInstance,computed } from 'vue';
import apiClient from '@/apiClient';
import { ElLoading } from 'element-plus'

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const curUser = store.getters.user;

const errors = ref({}); 
const filterItems = ref([]);
const filterItemVal = ref(1);
const historyData = ref([]);
const count = ref(5);
const curPoints = ref(0);

const noMore = computed(() => count.value >= historyData.value.length && count.value>0);
const disabled = computed(() => noMore.value);

function load() {
  
  setTimeout(() => {    
    
    if(count.value + 3 <= historyData.value.length)
      count.value += 3;
    else 
      count.value += historyData.value.length - count.value;

  }, 300);
}



function getAmountSymbol(isDeduct, isReward) {
  if(isReward) {
    return '+';
  }

  if(isDeduct) {
    return '-';
  } else {
    return '+'
  }
}


function getAmountFontColor(isDeduct, isReward) {

  if(isReward) {
    return 'amount-reward';
  }

  if(isDeduct) {
    return 'amount-red';
  } else {
    return 'amount-normal';
  }
}


function getOpt(opt) {
  if(opt === 0)
    return 'System';
  else if(opt === 1) 
    return 'Customer';
  else if(opt === 2)
    return 'Staff';
  else if(opt === 3)
    return 'Admin';
}

async function filterItemChange() {
  await loadHistoryData();
}


async function loadFilterItems() {
  try {
    const response = await apiClient.post('/Account/getHistoryFilterItem', {
      userId: curUser.userId,
      historyCatalog: 2,
    });

    if(response.data) {
      filterItems.value = response.data.filterItems;            
    }

  } catch(error) {
    if(error.response && error.response.data && error.response.status == 400) {                    
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
    } else {                
      if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logout');
        router.push('/');
      } else {
        toast.error(error.code);                    
      }                                                            
    }
  }
}


async function loadHistoryData() {

  const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

  try {
    const year = filterItemVal.value;
    const filterType = filterItemVal.value >= 2000 ? 5: filterItemVal.value;
   
    const response = await apiClient.post('/Account/getUserPointHistory', {
      userId: curUser.userId,
      filterType: filterType,
      designatedYear: year,
    });

    if(response.data) {
      historyData.value = response.data.hisPoints;
      curPoints.value = response.data.curPoints;
    }

  } catch(error) {
    if(error.response && error.response.data && error.response.status == 400) {                    
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
    } else {                
      if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logout');
        router.push('/');
      } else {
        toast.error(error.code);                    
      }                                                            
    }
  } finally {
    isLoading.close();
  }
}


onMounted(() =>{    

if(!store.getters.islogin) {
    router.push('/');
    return;
} 

loadFilterItems();

loadHistoryData();

});

</script>


<style scoped>
.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-top: 0px;
  width:100%;
}


.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 94%;
}

.infinite-list {
  height: 400px;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;  
  margin: 0px;  
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 8px;
}

.amount-red {
  color: red;
}

.amount-normal {
  color: #333;
}

.amount-reward {
  color: blue;
}

</style>