<template>    
    <div class="container">
      <div class="d-flex justify-content-between links" style="align-items: center;">
          <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
          <a href="#" @click.prevent="backToStep2">{{ $t('selSvrTime.linkPreStep') }} ></a>
      </div>
  
      <hr class="divider-line0"/>
  
      <div class="row">        
        <h2>{{ $t('selSvrTime.mainTitle') }}
        <p style="font-size: 16px; font-weight: 600; color: chocolate;"><span style="font-size: 15px; font-weight: 600; color:#333;">{{ $t('selSvrAdr.curSvrTitle') }}:</span> {{ $t(txtCatalog) }}</p>
        </h2>
        <el-descriptions :title="$t('selSvrDetail.addressTitle')">
          <el-descriptions-item label=""><span style="color:chocolate; font-weight: 600; margin: 0px; padding-top: 0px;"> {{ resultAddress.unit ? resultAddress.unit:'' }} {{ resultAddress.streetNo ? resultAddress.streetNo:'' }} 
            {{ resultAddress.streetName }}<br>&nbsp;&nbsp;&nbsp;&nbsp;{{ resultAddress.city }}, {{ provinceName }} {{ resultAddress.postal }}</span>
          </el-descriptions-item>                                        
        </el-descriptions>      
      </div>  
  
      <hr class="divider-line0"/>
  
      <div class="row">
        <el-descriptions :title="$t('selSvrTime.serviceDetailTitle')">
  
        </el-descriptions>
        <div class="summary">
          <div style="text-align: center; margin: 6px;">
            <span style="color:chocolate; font-weight: 600;">{{ resultService.name }}</span>
            <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
              <div>{{ $t('selSvrDetail.summaryBasePoints') }}: </div>
              <div>
                <span style="color:firebrick">{{ resultService.basePoints }}</span>
              </div>
            </div>
            <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
              <div>{{ $t('selSvrDetail.summaryAddPoints') }}: </div>
              <div>
                <span style="color:firebrick">{{ resultService.additionalPoints }}</span>
              </div>
            </div>
            <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
              <div style="color:green;">{{ $t('selSvrDetail.summaryRankDiscount') }}: </div>
              <div style="color:green;">{{ (rankDiscount/10).toFixed(1) }}%</div>
            </div>
            <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>
            <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
              <div style="color: firebrick; font-weight: 600;">{{ $t('selSvrDetail.summaryTotalPoints') }}:</div>
              <div style="color:firebrick; font-weight: 600">{{ resultService.totalPoints }}</div>
            </div>
            <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 10px;">
              <div></div>
              <div style="color:#333;">{{ $t('selSvrDetail.summaryEstimated') }}: ${{(resultService.totalPoints/100).toFixed(1)}}</div>
            </div>
            <div v-show="resultService.specialNotice" style="font-size:14px; text-align: left; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 0px;">
              <div style="color:#007185;"><img src="/misc/notice.png" style="width: 18px"/>&nbsp;{{ $t('selSvrDetail.summaryNotice') }}: {{ resultService.specialNotice }}</div>                          
            </div>
          </div>
        </div>
      </div>
  
      <hr class="divider-line1"/>
  
      <form @submit.prevent="handleSubmit">
        <div class="row">      
            <div v-if="period === 1">
                <div class="d-flex justify-content-center">
                    <div style="padding-top: 10px; padding-bottom: 12px;">                   
                        <el-date-picker
                        v-model="monthPeriod"
                        type="month"
                        placeholder="Pick a month"
                        style="width: 150px;"
                        />
                    </div>                
                </div>
                <div class="d-flex justify-content-center" style="padding-bottom: 16px;">
                    <div class="button-container">
                        <button type="submit" :disabled="loading" class="submit-button" style="width: 150px;">
                        <span v-show="!loading" style="font-size: 13px;">{{ $t('selSvrDetail.btnMonthly') }}</span>
                        <span v-show="loading" class="spinner"></span>
                        </button>
                    </div>     
                </div>         
            </div>       
            <div v-if="period === 2">
                <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 12px;">
                    <el-date-picker
                    v-model="monthPeriod"
                    type="year"
                    placeholder="Pick a year"
                    style="width: 150px;"
                    />
                </div>                
                <div class="d-flex justify-content-center" style="padding-bottom: 16px;">
                    <div class="button-container">
                        <button type="submit" :disabled="loading" class="submit-button" style="width: 150px;">
                        <span v-show="!loading" style="font-size: 13px;">{{ $t('selSvrDetail.btnYearly') }}</span>
                        <span v-show="loading" class="spinner"></span>
                        </button>
                    </div>     
                </div>       
                <div class="d-flex justify-content-center" style="padding-bottom: 20px; font-size: 12px;" v-if="catalog === 3">
                    <div style="padding-right: 2px; margin-top: 2px;"><el-icon><InfoFilled /></el-icon></div> 
                    <div>{{ $t('selSvrDetail.yearlyTip') }}</div>
                </div>  
            </div>                                 
        </div>
      </form>
  
      <hr class="divider-line0"/>
  
      <div class="row" style="padding-bottom: 16px;">
        <div class="d-flex justify-content-center" style="font-size: 14px;">
          {{ $t('selSvrTime.timezoneTip') }}<span style="font-weight: 600;">&nbsp;{{ proxy.$getTimeZone(new Date()) }}</span>.
        </div>
      </div>
  
    </div>
  </template>
      
  
  <script setup>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router'; 
  import { useToast } from 'vue-toastification';
  import { computed, getCurrentInstance } from 'vue';
  import { ref,onMounted } from 'vue';
  import apiClient from '@/apiClient.js';
  import { SERVICECATALOG,PROVINCES } from '@/constants';
  
  const store = useStore();
  const router = useRouter();
  const { proxy } = getCurrentInstance();
  const toast = useToast();
  const errors = ref({});
  const resultAddress = ref({});
  const loading = ref(false);   
  const rankDiscount = ref('');
  const resultService = ref({});
  const dayLatestBookTime = ref(14);
  const slotAMStartHour = ref(9);
  const slotAMEndHour = ref(11);
  const slotPMStartHour = ref(12);
  const slotPMEndHour = ref(14);
  const monthPeriod = ref('');
  
  const curUser = store.getters.user;
    if(!store.getters.islogin) {
        router.push('/');
    }
  
  const addressId = Number(router.currentRoute.value.params.addressId);
  const catalog = Number(router.currentRoute.value.params.catalog);
  const serviceId = Number(router.currentRoute.value.params.serviceId);
  const period = Number(router.currentRoute.value.params.period);
  
  const catalogItem = SERVICECATALOG.find(item => item.Id === catalog);        
  const txtCatalog = catalogItem ? catalogItem.key : 'emptyKey';

  const provinceName = computed(() => {
      const item = PROVINCES.find(item => item.Id === resultAddress.value.provinceId);
      return item ? item.name : '';
    });

  
  function backToStep2() {
      router.push({ name: 'selectServiceDetail', params: { catalog: catalog, addressId: addressId }});
  }
  

  async function loadServiceDetail() {
    
  try {
    const response = await apiClient.post('/Service/getUserAvailableServiceTime', {
      userId: curUser.userId,
      serviceCatalog: catalog,
      addressId: addressId,
      serviceId: serviceId
    });
  
    resultAddress.value = response.data.contactInfo;  
    resultService.value = response.data.service;
    rankDiscount.value = response.data.rankDiscount;
    dayLatestBookTime.value = response.data.dayLatestBookTime;
    slotAMStartHour.value = response.data.slotAMStartHour;
    slotAMEndHour.value = response.data.slotAMEndHour;
    slotPMStartHour.value = response.data.slotPMStartHour;
    slotPMEndHour.value = response.data.slotPMEndHour;
  
  } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
              errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
          } else {                
            if(error.response && error.response.status == 401) {
              toast.error('Your session has expired, please login again.');           
              store.dispatch('logout');
              router.push('/');
            } else {
              toast.error(error.code);                    
            }                                                            
      }
  }
  }
  
  function handleSubmit() {  
  
    loading.value = true;
      
    if(monthPeriod.value === '') {
        if(period === 1) {
            toast.error('Must select a valid month.');
        } else if(period === 2) {
            toast.error('Must select a valid year.');
        }
        
        loading.value = false;        
        return;
    }

    const selDate = new Date(monthPeriod.value);        
    const nowDate = new Date();

    if(period === 1) {        
        if(selDate.getFullYear() < nowDate.getFullYear()) {
            toast.error('Must select a valid month.');
            loading.value = false;        
            return;           
        } else if(selDate.getFullYear() === nowDate.getFullYear()) {          
           if(selDate.getMonth() < nowDate.getMonth()) {
                toast.error('Must select a valid month.');
                loading.value = false;        
                return;          
           }
        }     
    } else if(period === 2) {
        // Check if the now date is old then next year Apr
        const compDate = new Date(selDate.getFullYear()+1, 3, 30);        
        if(compDate.getFullYear() < nowDate.getFullYear()) {
            toast.error('Must select a valid year.');
            loading.value = false;        
            return;
        } else if(compDate.getFullYear() === nowDate.getFullYear()) {
            if(compDate.getMonth() < nowDate.getMonth()) {
                toast.error('Must select a valid year.');
                loading.value = false;        
                return;
            }
        }      
    }        
    

    setTimeout(() => {
      loading.value = false;      
  
      router.push({name: 'serviceOrderSummary', params: {
        catalog: catalog, 
        addressId: addressId, 
        serviceId: serviceId, 
        yyyy: selDate.getFullYear(),
        mm: selDate.getMonth()+1,
        dd: 1,
        slot:0,
        hm: 0,
        period: period
      }});
  
    }, 200);
  }
  
  
  onMounted(() =>{    
      loadServiceDetail();
  
    });
  
  </script>
      
  
  <style scoped>
  
  .el-calendar {
    height: auto; 
    overflow: hidden; 
  }
  
  .summary-container {
    display: flex;
    flex-direction: column; 
    align-items: center;    
    justify-content: center; 
    width: 100%;            
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .summary {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;    
    margin: 10px;
    overflow: hidden;  
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 00px;
    width: 94%;
  }
  
  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;  
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 94%;  
}

button:hover {
  background-color: #0056b3;
}

  .spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  h2 {
    margin-bottom: 24px;  
    font-weight: 500;
    font-size: 24px;
    color: #333;    
    text-align: center;    
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  h3 {
    margin-bottom: 14px;  
    padding-top: 10px;
    font-weight: 500;
    font-size: 20px;
    color: #333;         
  }
  
  .divider-line {
    border: none;
    border-top: 1px solid #ccc;
    margin: 30px 0;
  }
  
  .divider-line0 {
    border: none;
    border-top: 1px solid #ccc;
    margin: 0px 0;
    margin-bottom: 10px;
  }
  
  .divider-line1 {
    border: none;
    border-top: 1px solid #ccc;
    margin: 0px 0;
    margin-bottom: 10px;
    margin-top: 16px;
  }
  
  .links {
    text-align: left;
    width: auto;
  }
  
  .links a {
    display: block;
    color: #007aff;
    text-decoration: none;
    margin-bottom: 10px;
  }
  
  .links a:hover {
    text-decoration: underline;
  }
  
  
  .button-enabled {
    color: black;
    background-color: white;
    border: 1px solid black;
  }
  
  .button-disabled {
    color: gray;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 5px;  
  }
  
  button:disabled {
    cursor: not-allowed;
    
  }
  
  button:disabled img {
    filter: contrast(10%); 
  }
  
  
  ::v-deep .el-calendar-table th {
    text-align: center; 
    padding: 4px 0;    
    font-weight: bold;  
    font-size: 16px;    
  }
  
  ::v-deep .el-calendar-table td {
    border: none !important;      
  }
  ::v-deep .el-calendar-table td:hover {
    background-color: #ffffff;  
  }
  
  ::v-deep .el-calendar-day {
    font-size: 14px;
    font-weight: 500;   
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px !important;       
  }
  
  ::v-deep .current .el-calendar-day {  
    color: #333 !important;      
  }
  
  ::v-deep .current .el-calendar-day:hover {  
    background-color:#333;
    color: #ffffff !important;
  }
  
  ::v-deep .is-today .el-calendar-day {
    color: #333 !important;
    font-weight: bold !important;  
    border: 1px solid black;
  }
  
  ::v-deep .is-selected .el-calendar-day {  
    color: #ffffff !important;  
    font-weight: bold !important;
    border: 1px solid black;
    background-color: #333;
  }
  
  ::v-deep .next .el-calendar-day {
    font-size: 1pt !important;
    color:#ffffff !important;  
  }
  
  ::v-deep
    .next,
   .next .el-calendar-day {    
    cursor: not-allowed !important; 
    pointer-events: none !important; 
    opacity: 0.6 !important;   
  }
  
  ::v-deep .next .el-calendar-day:hover {
    background-color: #ffffff;
  }
  
  ::v-deep
    .prev,
   .prev .el-calendar-day {    
    cursor: not-allowed !important; 
    pointer-events: none !important; 
    opacity: 0.6 !important;   
  }
  
  ::v-deep .prev .el-calendar-day:hover {
    background-color: #ffffff;
  }
  
  ::v-deep .prev .el-calendar-day {
    font-size: 1pt !important;
    color:#ffffff !important;  
  }
  
  ::v-deep   
    .disabled-date {
    color: gray;  
    background-color: #ffffff;
    opacity: 0.6;
    text-decoration: line-through;
  }
  
  ::v-deep   
    .current,
    .disabled-date {
      cursor: not-allowed !important; 
      pointer-events: none !important;
  }
  
  ::v-deep 
    .current,
    .enabled-date {
    cursor: pointer;
    pointer-events:all; 
    opacity:inherit;
  }
  
  .radio-text {
    white-space: normal; 
    word-wrap: break-word;
    display: inline-block; 
    align-items: flex-start;
  }
  
  .confirmButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 70%;  
  }
  
  .confirmButton:hover {
    background-color: #0056b3;
  }
  
  .button-container {
    display: flex;
    justify-content: center; 
    width: 100%;
    margin-bottom: 10px; 
  }
  
  
  .el-time-select .el-input {
    zoom: 0.9; 
  }
  
  </style>
      